
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class MiscPopulationAndHousing extends Vue {
        private readonly ukPopulationByYear = {
            // tslint:disable
            traces: [
                {
                    type: "scatter",
                    x: [  "2020",   "2000",   "1980",   "1960",   "1940",   "1920",   "1900",   "1800",  "1700"],
                    y: [67886011, 58890000, 56330000, 52370000, 46030000, 43650000, 32527813, 10500000, 5100000],
                    marker: {
                        color: 'hsl(180, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(240, 100%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly ukCityPopulations = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: [10236000,      2639000,      2512000, 1893000,   1220000,         883000,      793000,       755000,      706000,    646000,    600000,      552267,      534000],
                    y: ["London", "Manchester", "Birmingham &<br>Wolverhampton", "Leeds &<br>Bradford", "Glasgow", "Southhampton<br>& Portsmouth", "Newcastle", "Nottingham", "Sheffield", "Bristol", "Belfast", "Liverpool", "Leicester"],
                    orientation: 'h',
                    marker: {
                        color: ['hsl(120, 100%, 35%)', 'hsl(125, 100%, 35%)', 'hsl(130, 100%, 35%)', 'hsl(135, 100%, 35%)', 'hsl(140, 100%, 35%)', 'hsl(145, 100%, 35%)', 'hsl(150, 100%, 35%)', 'hsl(155, 100%, 35%)', 'hsl(160, 100%, 35%)', 'hsl(165, 100%, 35%)', 'hsl(170, 100%, 35%)', 'hsl(175, 100%, 35%)', 'hsl(180, 100%, 35%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly housesBuiltInEngland = {
            // tslint:disable
            traces: [
                {
                    type: "scatter",
                    x: ["2017", "2010", "2000", "1990", "1980", "1970", "1960", "1950", "1940", "1930", "1923"],
                    y: [175000, 125000, 140000, 170000, 205000, 290000, 265000, 165000,  55000, 200000,  80000],
                    marker: {
                        color: ['hsl(50, 100%, 40%)', 'hsl(30, 100%, 40%)', 'hsl(40, 100%, 40%)', 'hsl(45, 100%, 40%)', 'hsl(60, 100%, 40%)', 'hsl(120, 100%, 40%)', 'hsl(110, 100%, 40%)', 'hsl(48, 100%, 40%)', 'hsl(0, 100%, 40%)', 'hsl(70, 100%, 40%)', 'hsl(20, 100%, 40%)'],
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly lowestHousePrice = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: [  260000,         170000,    169995,      115000,     96500,        80000,        76500,        69950,       65000,   60000,       59950,     45000,       40000],
                    y: ["London", "Southampton", "Bristol", "Leicester", "Belfast", "Nottingham", "Birmingham", "Manchester", "Sheffield", "Leeds", "Newcastle", "Glasgow", "Liverpool"],
                    orientation: 'h',
                    marker: {
                        color: ['hsl(0, 100%, 35%)', 'hsl(12, 100%, 35%)', 'hsl(24, 100%, 35%)', 'hsl(36, 100%, 35%)', 'hsl(48, 100%, 35%)', 'hsl(60, 100%, 35%)', 'hsl(72, 100%, 35%)', 'hsl(84, 100%, 35%)', 'hsl(96, 100%, 35%)', 'hsl(108, 100%, 35%)', 'hsl(120, 100%, 35%)', 'hsl(132, 100%, 35%)', 'hsl(144, 100%, 35%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly averageUkHousePrices = {
            // tslint:disable
            traces: [
                {
                    type: "scatter",
                    x: [ "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
                    y: [184267, 158004, 170846, 167470, 168395, 171234, 185476, 195313, 210872, 219954, 226834, 229423, 230332],
                    marker: {
                        color: ['hsl(70, 100%, 35%)', 'hsl(120, 100%, 35%)', 'hsl(90, 100%, 35%)', 'hsl(95, 100%, 35%)', 'hsl(93, 100%, 35%)', 'hsl(90, 100%, 35%)', 'hsl(65, 100%, 35%)', 'hsl(55, 100%, 35%)', 'hsl(40, 100%, 35%)', 'hsl(20, 100%, 35%)', 'hsl(10, 100%, 35%)', 'hsl(5, 100%, 35%)', 'hsl(0, 100%, 35%)'],
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private get createPopulationLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "Population", 80, 32);
            // tslint:enable
        }

        private get createCityPopulationLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("Population", "", 120, 32);
            // tslint:enable
        }

        private get createHousePricesLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("(£) House Price", "", 120, 48);
            // tslint:enable
        }

        private get createUkHousePricesLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "(£) House Price", 80, 32);
            // tslint:enable
        }

        private get createHousesBuiltLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "Total Houses Built", 80, 32);
            // tslint:enable
        }

        // Data function
        private data() {
            return {
                populationIconPathAndName:
                    require("@/assets/images/icon_population.png"),

                housingIconPathAndName:
                    require("@/assets/images/icon_housing.png"),
            };
        }
    }
